import 'react-day-picker/lib/style.css';
import 'react-square-payment-form/lib/default.css';
import 'react-phone-number-input/style.css';
import './index.css';

import {
  ADDRESS_MAX_LENGTH,
  DATEPICKER_MODIFIERS,
  DATEPICKER_MODIFIERS_STYLES,
  NAME_MAX_LENGTH,
  STATES_TIMEZONES,
  ZIP_MAX_LENGTH,
} from '@/constants';

import { Fragment } from 'react';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { appointmentService, couponService, userService } from '@/services';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SquarePaymentComponent } from '../../../components/SquarePaymentComponent';
import { ConfirmScript } from './Confirm/index';
import { CalendarIcon } from '@heroicons/react/outline';
import DayPicker from 'react-day-picker';
import { GlobalContext } from '@/context';
import Input from '@/components/Inputs';
import LoadingOverlay from '@/components/LoadingOverlay';
import NoAuthTopBar from '@/components/NoAuthTopBar';
import PhoneInput from 'react-phone-number-input/input';
import Select from 'react-select';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { getFifteenMinuteIntervals, sample } from '@/utils';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';

const allowTimezones = Object.keys(allTimezones)
  .filter((key) => key.startsWith('America'))
  .reduce((accumulator, key) => {
    accumulator[key] = allTimezones[key];
    return accumulator;
  }, {});

const ScheduleAppointmentView = () => {
  let history = useHistory();

  const [step, setStep] = useState('schedule');
  const [loading, setLoading] = useState(false);
  const {
    currentPlatform,
    currentUser,
    setCurrentUser,
    setUserType,
    allStates,
  } = useContext(GlobalContext);

  const [resultError, setResultError] = useState('');
  const [cardType, setCardType] = useState(null);
  const [appointmentType, setAppointmentType] = useState('phone');
  const [couponCode, setCouponCode] = useState(null);
  const [coupon, setCoupon] = useState({
    name: '',
    amount: 0,
    code: '',
  });
  const [card, setCard] = useState(() => undefined);
  const [appointmentId, setAppointmentId] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [allSlots, setAllSlots] = useState([]);
  const [updateUserInfo, setUpdateUserInfo] = useState({
    name: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    phone_number: '',
  });
  const [selectedZDateTime, setSelectedZDateTime] = useState(null);
  const [timezone, setTimezone] = useState({
    value: 'America/Detroit',
  });
  // const [isSquareLoaded, setIsSquareLoaded] = useState(false);

  const formRef = useRef();

  const selectedStateInfo = useMemo(() => {
    if (!updateUserInfo) {
      return null;
    }

    return allStates.find((item) => item.code === updateUserInfo.state);
  }, [allStates, updateUserInfo]);

  const availableTimes = useMemo(() => {
    if (!allSlots.length) return [];

    function roundUpTo15Minutes(momentObj) {
      const remainder = 15 - (momentObj.minute() % 15);
      return momentObj.add(remainder, 'minutes').startOf('minute');
    }

    const now = roundUpTo15Minutes(moment());
    const todaySelected =
      selectedDate.toLocaleDateString() === now.format('M/D/YYYY');

    const selectedDateObj = todaySelected
      ? now
      : moment.tz(selectedDate, timezone.value).startOf('day');
    const intervals = getFifteenMinuteIntervals(
      selectedDateObj.clone().tz('zulu'),
      selectedDateObj.endOf('day').clone().tz('zulu'),
    );

    return intervals.filter((zDateTime) =>
      allSlots.find((slot) => slot.date_time === zDateTime),
    );
  }, [allSlots, selectedDate, timezone]);

  const startTimeMoment = useMemo(() => {
    if (currentUser) {
      return currentUser.timezone
        ? moment.tz(selectedZDateTime, 'zulu').tz(currentUser.timezone)
        : moment
            .tz(selectedZDateTime, 'zulu')
            .tz(STATES_TIMEZONES[currentUser.state]);
    }
  }, [currentUser, selectedZDateTime, timezone]);

  useEffect(() => {
    loadSlots();
    loadUser();
  }, []);

  useEffect(() => {
    if (currentUser && allSlots.length) {
      setLoading(false);
      findNextAvailable();
    }
  }, [currentUser, allSlots]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.timezone) {
        setTimezone({
          value: currentUser.timezone,
        });
      } else {
        setTimezone({
          value: STATES_TIMEZONES[currentUser.state],
        });
      }

      setUpdateUserInfo({
        ...updateUserInfo,
        state: currentUser.state,
      });
    }
  }, [currentUser]);

  const onStateChange = (value) => {
    setUpdateUserInfo({
      ...updateUserInfo,
      state: value.value,
    });
  };

  const loadSlots = async () => {
    setLoading(true);
    try {
      const {
        data: { slots },
      } = await appointmentService.getAvailableSlots();
      setAllSlots(slots);
    } catch (err) {
      console.error('no empty blocks', err);
    }
  };

  const findNextAvailable = () => {
    if (!allSlots.length) return [];

    const zuluNow = moment().clone().tz('zulu');

    for (let i = 0; i < allSlots.length; i++) {
      const thisSlot = allSlots[i];
      const thisMoment = moment.tz(thisSlot.date_time, 'zulu');

      if (thisMoment.isAfter(zuluNow)) {
        setSelectedDate(thisMoment.tz(timezone.value).toDate());
        return;
      }
    }
  };

  const loadUser = async () => {
    setLoading(true);
    try {
      if (!currentUser) {
        const { data: user } = await userService.getCurrentUser();
        localStorage.setItem('user', JSON.stringify(user));
        setCurrentUser(user);
        setUserType(userService.getTopType(user));
        setUpdateUserInfo({
          address1: user.address1,
          city: user.city,
          state: user.state,
          zip: user.zip,
        });
      } else {
        setUpdateUserInfo({
          address1: currentUser.address1,
          city: currentUser.city,
          state: currentUser.state,
          zip: currentUser.zip,
        });
      }
    } catch (err) {
      console.error('no user', err);
    }
  };

  const handleDateSelection = async (d, modifiers) => {
    console.log('date', d);
    if (modifiers.disabled) {
      return;
    }
    setSelectedZDateTime(null);
    setSelectedDate(d);
  };

  const formatSlot = (slotTime) => {
    const zTime = moment.tz(slotTime, 'zulu');

    return zTime.tz(timezone.value).format('h:mm a');
  };

  const handlePaymentCallback = async (card) => {
    setLoading(true);
    setResultError('');
    const tokenResult = await card?.tokenize();
    if (tokenResult?.status !== 'OK') {
      setResultError(tokenResult.errors[0].message);
      setLoading(false);
      return;
    }

    if (!updateUserInfo.name) {
      setResultError('Full legal name is required.');
      setLoading(false);
      return;
    }

    if (
      updateUserInfo.address1 &&
      updateUserInfo.address1.length > ADDRESS_MAX_LENGTH
    ) {
      setResultError(
        `Maximum length of street address is ${ADDRESS_MAX_LENGTH}.`,
      );
      setLoading(false);
      return;
    }

    if (updateUserInfo.city && updateUserInfo.city.length > NAME_MAX_LENGTH) {
      setResultError(`Maximum length of city is ${NAME_MAX_LENGTH}.`);
      setLoading(false);
      return;
    }

    if (updateUserInfo.zip && updateUserInfo.zip.length > ZIP_MAX_LENGTH) {
      setResultError(`Maximum length of zip is ${ZIP_MAX_LENGTH}.`);
      setLoading(false);
      return;
    }

    if (!cardType) {
      setResultError('Card type is required.');
      setLoading(false);
      return;
    }

    if (
      !(
        updateUserInfo.phone_number &&
        updateUserInfo.phone_number.replaceAll(/\D/g, '').match(/^1\d{10}$/)
      )
    ) {
      setResultError('US formatted phone number is required.');
      setLoading(false);
      return;
    }

    try {
      const availableSlot = allSlots.find(
        (slot) => slot.date_time === selectedZDateTime,
      );

      const startTime = moment.tz(selectedZDateTime, 'zulu');
      const payload = {
        source_id: tokenResult.token,
        provider_paid: true,
        provider_notes: [],
        appointment_status: 'incomplete',
        platform_id: currentPlatform.uid,
        appointment_start_time: startTime.toISOString(),
        appointment_end_time: startTime.add(15, 'minute').toISOString(),
        patient_id: currentUser.uid,
        provider_id: sample(availableSlot.providers),
        card_type: cardType.value,
        appointment_type: appointmentType,
        coupon_code: coupon.code,
      };

      const userPayload = Object.keys(updateUserInfo).reduce((result, key) => {
        if (key === 'name') {
          return result;
        }

        if (updateUserInfo[key]) {
          result[key] = updateUserInfo[key];
        } else {
          result[key] = null;
        }

        return result;
      }, {});
      await Promise.all([
        appointmentService.createAppointment(payload),
        userService.updateUser({
          uid: currentUser.uid,
          ...userPayload,
        }),
      ]).then((values) => {
        setAppointmentId(values[0].data.uid);
      });
      setCurrentUser({
        ...currentUser,
        ...userPayload,
      });
      // Google tag conversion
      window.gtag('event', 'create_appointment', {
        currency: 'USD',
        value: Number((149.99 - coupon.amount).toFixed(2)),
        coupon: coupon.code,
        items: [{ ...payload }],
      });
      // Flex Offers conversion
      if (window.flextrack) {
        !(function () {
          var e = window.flextrack;
          if (e.invoked)
            window.console &&
              console.error &&
              console.error('FlexOffers library was included more than once.');
          else {
            (e.invoked = !0),
              (e.methods = ['init', 'getClick', 'track']),
              (e.factory = function (r) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  return t.unshift(r), e.push(t), e;
                };
              });
            for (var r = 0; r < e.methods.length; r++) {
              var t = e.methods[r];
              e[t] = e.factory(t);
            }
            e.init = function (r) {
              var t = document.createElement('script');
              (t.type = 'text/javascript'),
                (t.async = !0),
                (t.src =
                  'https://advertiserpro.flexoffers.com/vendors/flexoffers/flexoffers.tracking.lib.js');
              var n = document.getElementsByTagName('script')[0];
              n.parentNode.insertBefore(t, n), (e.advertiserId = r);
            };
          }
        })();
        window.flextrack.init('02e1afd9-5278-4694-92d4-72bc482223fb');

        window.flextrack.track({
          order_amount: Number((149.99 - coupon.amount).toFixed(2)),
          order_coupon: coupon.code,
          order_currency: 'USD',
          order_commissionid: payload.provider_id,
          order_items: [{ ...payload }],
        });
      }

      setStep('confirm');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errorMessage) {
        setResultError(err.response.data.errorMessage);
      } else {
        setResultError(err.message);
      }
    }
    setLoading(false);
  };

  const handleBack = () => setStep('schedule');
  const handleContinue = () => {
    if (step === 'pay') {
      handlePaymentCallback(card);
    } else {
      window.gtag('event', 'pay_landing', {
        value: '0',
      });
      setStep('pay');
    }
  };
  const handleApplyButton = async () => {
    setResultError('');
    try {
      setLoading(true);

      const resp = await couponService.getCouponCode(couponCode);

      if (resp.coupon_info && resp.coupon_info.amount) {
        setCoupon({ ...resp.coupon_info });
      } else {
        setResultError(resp.error);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onUserInfoUpdate = (fieldName) => (event) => {
    setUpdateUserInfo({
      ...updateUserInfo,
      [fieldName]: event.target.value,
    });
  };

  const onPhoneNumberUpdate = (value) => {
    setUpdateUserInfo({
      ...updateUserInfo,
      phone_number: value,
    });
  };

  useEffect(() => {
    // if user clicks back button, we need to detach and re-attach the Square card
    if (card && step == 'schedule') {
      card.detach();
    }
  }, [step]);

  return (
    <div className="ScheduleFlow flex flex-col items-center h-screen bg-mmj-gray overflow-auto">
      <NoAuthTopBar />
      <div className="Assessment__content w-screen flex flex-col items-center py-10">
        <div className="text-left mb-6 w-2/3">
          <h1 className="text-3xl font-bold">Schedule an appointment</h1>
        </div>
        <div className="Assessment__content__question p-6 bg-white w-11/12 lg:w-2/3 rounded-lg shadow-mmj-lg flex flex-col space-y-4">
          {/* Schedule Step */}
          {step === 'schedule' && (
            <div className="flex flex-col sm:flex-row space-x-4">
              <div className="w-full sm:w-1/2">
                <DayPicker
                  className="border rounded-lg p-3 w-full mb-3"
                  onDayClick={handleDateSelection}
                  selectedDays={selectedDate}
                  disabledDays={{
                    from: new Date(1900, 1, 1),
                    to: moment(
                      moment().tz(timezone.value).format('YYYY-MM-DD HH:mm:ss'),
                    )
                      .subtract(1, 'days')
                      .toDate(),
                  }}
                  fromMonth={new Date()}
                  modifiers={DATEPICKER_MODIFIERS(timezone.value)}
                  modifiersStyles={DATEPICKER_MODIFIERS_STYLES}
                  showOutsideDays
                />
                {currentUser && (
                  <TimezoneSelect
                    value={timezone}
                    onChange={setTimezone}
                    timezones={allowTimezones}
                    classNamePrefix="mmj"
                  />
                )}
              </div>
              <div className="flex-grow flex flex-col p-2 relative">
                <LoadingOverlay show={loading} />
                <div className="text-lg font-semibold ">Select a time</div>
                <div className="h-64 overflow-hidden overflow-y-auto flex flex-col space-y-2">
                  {availableTimes.length > 0 && !loading ? (
                    availableTimes.map((zDateTime) => (
                      <Transition
                        as={Fragment}
                        key={JSON.stringify(zDateTime)}
                        show={availableTimes.includes(zDateTime)}
                        enter="transition duration-700"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                      >
                        <div
                          className={classNames(
                            'px-4 py-2 rounded-lg border w-full max-h cursor-pointer',
                            {
                              'text-white bg-mmj-blue':
                                selectedZDateTime === zDateTime,
                            },
                          )}
                          onClick={() => setSelectedZDateTime(zDateTime)}
                        >
                          {formatSlot(zDateTime)}
                        </div>
                      </Transition>
                    ))
                  ) : (
                    <div>
                      No slots available, please select a different date.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Payment Step */}
          {step === 'pay' && (
            <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4 relative">
              <LoadingOverlay show={loading} />
              <div className="w-full md:w-1/2 bg-mmj-blue shadow-mmj-lg p-4 rounded-lg flex flex-col space-y-2">
                <p className="text text-white">
                  You are scheduling an appointment for:
                </p>
                <div className="flex text-white space-x-2 py-4 font-semibold">
                  <CalendarIcon className="h-6 w-6" />
                  {startTimeMoment.format('MMMM Do YYYY \\at h:mm a')}
                </div>
                <p className="text text-white">
                  <strong>Your appointment is not yet booked! </strong>
                </p>
                <p className="text text-white">
                  Please complete the form below and submit your payment to book
                  your appointment.
                </p>
              </div>
              <form ref={formRef} className="w-full md:flex-1 space-y-4">
                <h1 className="text-xl font-bold">Appointment details</h1>
                <div className="w-full">
                  <Input
                    label="Full legal name"
                    id="name"
                    type="text"
                    value={
                      updateUserInfo && updateUserInfo.name
                        ? updateUserInfo.name
                        : ''
                    }
                    onChange={onUserInfoUpdate('name')}
                  />
                </div>
                <div className="w-full">
                  <Input
                    label="Primary street address"
                    id="address1"
                    type="text"
                    value={
                      updateUserInfo && updateUserInfo.address1
                        ? updateUserInfo.address1
                        : ''
                    }
                    onChange={onUserInfoUpdate('address1')}
                  />
                  <p className="text-xs text-right text-gray-500 mt-1">
                    Max length: {ADDRESS_MAX_LENGTH}
                  </p>
                </div>
                <div className="w-full space-y-4 md:flex md:space-x-4 md:space-y-0">
                  <div className="w-full md:w-1/3">
                    <Input
                      label="City"
                      type="text"
                      name="city"
                      onChange={onUserInfoUpdate('city')}
                      id="city"
                      value={updateUserInfo.city || ''}
                    />
                    <p className="text-xs text-right text-gray-500 mt-1">
                      Max length: {NAME_MAX_LENGTH}
                    </p>
                  </div>

                  <div className="w-full md:w-1/3">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      State
                    </label>
                    <Select
                      isDisabled
                      value={
                        updateUserInfo &&
                        updateUserInfo.state &&
                        selectedStateInfo
                          ? {
                              value: selectedStateInfo.code,
                              label: selectedStateInfo.name,
                            }
                          : null
                      }
                      onChange={onStateChange}
                      classNamePrefix="mmj"
                      options={allStates.map((state) => ({
                        value: state.code,
                        label: state.name,
                      }))}
                    />
                  </div>

                  <div className="w-full md:w-1/3">
                    <Input
                      label="ZIP"
                      type="text"
                      name="zip"
                      id="zip"
                      value={updateUserInfo.zip || ''}
                      onChange={onUserInfoUpdate('zip')}
                      autoComplete="postal-code"
                    />
                    <p className="text-xs text-right text-gray-500 mt-1">
                      Max length: {ZIP_MAX_LENGTH}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700">
                    MMJ Card Type
                    <div className="mt-1">
                      <Select
                        value={cardType}
                        onChange={setCardType}
                        classNamePrefix="mmj"
                        options={[
                          {
                            value: 'new-card',
                            label: 'New card',
                          },
                          {
                            value: 'renewal',
                            label: 'Renewal',
                          },
                        ]}
                        isSearchable={false}
                      />
                    </div>
                  </label>
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700">
                    Appointment Type
                  </label>
                  <div className="lg:flex">
                    <div className="w-full mt-1 flex flex-col justify-end">
                      <button
                        className={
                          'button button-' +
                          (appointmentType === 'phone' ? 'primary' : 'default')
                        }
                        type="button"
                        onClick={() => setAppointmentType('phone')}
                      >
                        {appointmentType === 'phone' && (
                          <span className="checkmark">
                            <div className="checkmark_stem"></div>
                            <div className="checkmark_kick"></div>
                          </span>
                        )}
                        Phone Call
                      </button>
                    </div>
                    <div className="w-full mt-1 flex flex-col justify-end">
                      <button
                        className={
                          'button button-' +
                          (appointmentType === 'video' ? 'primary' : 'default')
                        }
                        type="button"
                        onClick={() => setAppointmentType('video')}
                      >
                        {appointmentType === 'video' && (
                          <span className="checkmark">
                            <div className="checkmark_stem"></div>
                            <div className="checkmark_kick"></div>
                          </span>
                        )}
                        Video Conference
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone number
                    <div className="mt-1">
                      <PhoneInput
                        className="shadow-sm block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        international
                        country="US"
                        withCountryCallingCode
                        value={updateUserInfo.phone_number}
                        onChange={onPhoneNumberUpdate}
                      />
                    </div>
                  </label>
                </div>
                <div className="w-full">
                  <label className="block font-medium mt-1 text-gray-700 text-sm">
                    SMS Sign Up:
                    <div className="flex mt-1">
                      <input
                        className="border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 py-2 rounded-md shadow-sm sm:text-sm text-base"
                        type="checkbox"
                        name="text-opt-in"
                      />
                      <p>
                        &nbsp;By signing up, you consent to receive automated
                        transactional messages. For details, review our Terms
                        and Privacy Policy at mmj.com/terms-of-use/. You may
                        receive up to 2 messages per month. Standard message and
                        data rates may apply. Reply STOP to opt out or HELP for
                        assistance.
                      </p>
                    </div>
                  </label>
                </div>
                <div className="flex">
                  <div className="w-1/2">
                    <Input
                      label="Coupon Code"
                      id="coupon"
                      type="text"
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-1/6"> </div>
                  <div className="w-1/4 flex flex-col justify-end">
                    <button
                      className="button button-primary"
                      type="button"
                      onClick={handleApplyButton}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <h1 className="text-xl font-bold pt-2">Payment info</h1>
                <div id="square-card-target"></div>
                <SquarePaymentComponent
                  card={card}
                  setCard={setCard}
                ></SquarePaymentComponent>
                <div className="w-full bg-mmj-lightblue text-mmj-header font-semibold rounded-lg">
                  {coupon.name && coupon.amount && (
                    <>
                      <div className="flex justify-between p-2">
                        <span>Initial Cost:</span>
                        <span>$149.99</span>
                      </div>
                      <div className="flex justify-between p-2">
                        <span>{coupon.name}</span>
                        <span>- ${coupon.amount}</span>
                      </div>
                    </>
                  )}
                  <div className="flex justify-between p-2">
                    <span>Total due:</span>
                    <span>${(149.99 - coupon.amount).toFixed(2)}</span>
                  </div>
                  <p className="p-2">
                    Your card will be charged for this amount when you book this
                    appointment.
                  </p>
                </div>
              </form>
            </div>
          )}
          {step === 'confirm' && (
            <div className="flex flex-col space-y-4">
              <div className="w-full bg-mmj-blue shadow-mmj-lg p-4 pt-32 rounded-lg flex flex-col space-y-2">
                <div className="text-3xl font-bold text-white">Confirmed</div>
                <div className="text-xs text-white opacity-75 capitalize">
                  your appointment
                </div>
                <div className="flex text-white space-x-2 font-semibold">
                  <CalendarIcon className="h-6 w-6" />
                  {startTimeMoment.format('MMMM Do YYYY \\at h:mm a')}
                </div>
              </div>
              <div className="w-full bg-mmj-lightblue text-mmj-header font-semibold rounded-lg flex justify-between p-2">
                <div>Total Paid</div>
                <div>${(149.99 - coupon.amount).toFixed(2)}</div>
                <ConfirmScript
                  appointmentId={appointmentId}
                  cost={(149.99 - coupon.amount).toFixed(2)}
                ></ConfirmScript>
              </div>
              <div className="text-xs text-center text-gray-600">
                You&apos;ll be sent an email with a receipt and confirmation of
                your appointment
              </div>
            </div>
          )}
          {step !== 'confirm' && (
            <>
              {resultError && (
                <div className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl">
                  {resultError}
                </div>
              )}
              <div className="flex justify-between">
                <div>
                  <button
                    className={classNames('button', {
                      hidden: step === 'schedule',
                    })}
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    className="button button-primary"
                    onClick={handleContinue}
                    disabled={!selectedZDateTime || loading}
                  >
                    {step === 'pay' ? 'Book appointment' : 'Continue'}
                  </button>
                </div>
              </div>
            </>
          )}
          {step === 'confirm' && (
            <div className="flex justify-center">
              <div>
                <button
                  className="button button-primary"
                  onClick={() => history.push('/')}
                >
                  Continue to my Dashboard
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointmentView;
